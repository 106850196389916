import { gql } from "@apollo/client";

export const GET_WORKSPACE = gql`
  query GetWorkspace($mrn: String!) {
    workspace(mrn: $mrn) {
      ... on Workspace {
        mrn
        ownerMrn
        name
        description
        priorityFindings
        selections {
          selections {
            conditions {
              operator
              condition {
                ... on WorkspaceSelectionStringCondition {
                  stringField: field
                  stringOperator: operator
                  stringValues: values
                }
                ... on WorkspaceSelectionIntCondition {
                  intField: field
                  intOperator: operator
                  intValues: values
                }
              }
            }
          }
        }
      }
      ... on RequestError {
        code
        message
      }
      ... on NotFoundError {
        code
        message
      }
    }
  }
`;
