import { gql } from "@apollo/client";

export const UPDATE_WORKSPACE = gql`
  mutation UpdateWorkspace($input: UpdateWorkspaceInput!) {
    updateWorkspace(input: $input) {
      mrn
      name
      description
      selections {
        selections {
          conditions {
            operator
            condition {
              ... on WorkspaceSelectionStringCondition {
                stringField: field
                stringOperator: operator
                stringValues: values
              }
              ... on WorkspaceSelectionIntCondition {
                intField: field
                intOperator: operator
                intValues: values
              }
            }
          }
        }
      }
    }
  }
`;
