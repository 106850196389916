import { gql } from "@apollo/client";

export const GET_WORKSPACES = gql`
  query GetWorkspaces($input: WorkspacesInput!, $after: String, $first: Int) {
    workspaces(input: $input, after: $after, first: $first) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          mrn
          ownerMrn
          name
          description
          selections {
            selections {
              conditions {
                operator
                condition {
                  ... on WorkspaceSelectionStringCondition {
                    stringField: field
                    stringOperator: operator
                    stringValues: values
                  }
                  ... on WorkspaceSelectionIntCondition {
                    intField: field
                    intOperator: operator
                    intValues: values
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
